@import url('https://fonts.googleapis.com/css?family=Arvo');
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');
@import url('https://fonts.googleapis.com/css?family=Bebas');
@import url('https://fonts.googleapis.com/css?family=Lato');
/* @import url('https://fonts.googleapis.com/css?family=Helvetica-Neue'); */

p, h1, h2, h3, input, textarea {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

p {
  margin-bottom: auto;
  margin-top: auto;
}

input {
  color: #48525D;
  border-radius: 0;
}

textarea {
  color: #48525D;
  border-radius: 0;
}

textarea::placeholder {
  white-space: pre-wrap;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #BECAD7;
  opacity: 1; /* Firefox */
}

div {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

div {
  /* flex-direction: column; */
}

.AccessDeniedBlock {
  background-color: #DE6161;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: white;
  width: 100%;
  max-width: 500px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 15px;
  box-sizing: border-box;
  flex-direction: column;
  /* position: fixed; */
  /* top: 150px; */
}

.AccessDeniedBlock p{
  font-size:28px;
  font-family: 'Arvo', sans-serif;
  text-align: center;
  margin-top: 0;
}

.AccessDeniedBlock h1{
  font-size:60px;
  font-family: 'Bebas', sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.BackButton {
  float: left;
  vertical-align: middle;
  cursor: pointer;
}

.BgWhite {
  background-color: white;
}

.BgBlue {
  background-color: #7EBBC6;
  color: white;
}

.BR5 {
  border-radius: 5px
}
.BR8 {
  border-radius: 8px
}
.BR10 {
  border-radius: 10px
}
.BR15 {
  border-radius: 15px
}
.BR20 {
  border-radius: 20px
}

Button {
  font-size: 18px;
  font-weight: bold;
  border: 0;
  outline: 0;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  /* margin: 15px; */
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  /* border-radius: 5px; */
}

Button:disabled {
  opacity: 0.5;
  cursor: auto;
}

Button.Square {
  border-radius: 5px;
}

Button.Round {
  border-radius: 500px;
}

Button.Yellow {
  background-color: #D7B174;
  background-color: #E4C28C;
  background-color: #F0B666;
  color: white;
}

Button.Green {

  background-color: #B6D488;
  color: white;
}

Button.Red {
  background-color: #DE6161;
  color: white;
}

Button.Blue {
  background-color: #7EBBC6;
  color: white;
}

Button.OutlineBlue {
  background-color: white;
  color: #4DA2B0;
  border: 2px solid #4DA2B0;
}

Button.Blue.Outline {
  outline: 4px solid #4DA2B0;
  outline: 4px solid #79EBFF;
  outline: 4px solid #77D9EB;
}

Button.H40 {
  min-height: 40px;
  /* padding-left: 25px;
  padding-right: 25px; */
}
Button.H50 {
  min-height: 50px;
  /* padding-left: 25px;
  padding-right: 25px; */
}

Button.H70 {
  min-height: 70px;
  font-size: 24px;
  /* padding-left: 25px;
  padding-right: 25px; */
}

Button.W200 {
  min-width: 200px;
}

Button.W100 {
  width: 100%;
}

.CheckList {
  padding: 0;
}

.CheckList li{
  list-style-type: none;
  color: #48525D;
  font-weight: bold;
  font-family: Lato;
}


.CheckList li:not(:last-child){
  margin-bottom: 8px;
}

.CheckList li .checked{
  color: #A6ACB2;
  text-decoration: line-through;
}

.ContinueButton {
  /* background-color: green; */
  border-radius: 10px;
  background-color: transparent;
  height: 54px;
  padding-left: 25px;
  padding-right: 25px;
  align-self: center;
  margin: auto;
  /* min-width: 330px; */
  /* width: 330px; */
  max-width: 400px;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  color: #7EC696;
  text-transform: uppercase;
  font-size: 18px;
  color: #7EC696;
  font-weight: bold;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  bottom: 0;
  left: auto;
  right: auto; */
  /* margin-left: 20px;
  margin-right: 20px; */
}

.ContinueButton:active {
  /* transform: translateY(-2px); */
  opacity: 0.5;
  /* outline: 2px solid white;
  outline-offset: 4px; */
}


.ContinueButton.outline {
  border: 2px solid #7EC696;
  border-radius: 10px;
  /* background-color: transparent; */
}

.ContinueButton.outline.white {
  border-color: white;
  color: white;
}



.ContinueButton.solid {
  border: 0;
}

.ContinueButton.solid.white {
  background-color: white;
  color: #4DA2B0;
}

.ContinueButton.disabled {
  border: 2px solid lightGrey;
  border-radius: 10px;
  cursor: default;
  /* background-color: transparent; */
}

.ContinueButton p {
  text-transform: uppercase;
  font-size: 18px;
  /* color: #7EC696; */
  font-weight: bold;
  margin: auto;
}

.ContinueButton.disabled p {
  color: lightGrey;
}

.RoundButton {
  /* background-color: green; */
  height: 54px;
  padding-left: 25px;
  padding-right: 25px;
  align-self: center;
  margin: auto;
  border-radius: 300px;
  /* min-width: 330px; */
  /* width: 330px; */
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  outline: none;
  border: 0px;
}



/* .BackButtonContainer {
  height: 20px;
} */

.AddressInputDiv {
  display: inline;
}

.AgreementFinalisePage {
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 620px;
  width: 100%;
}

.BlurBehind {
  /* background-color: rgba(150, 150, 150, 0.3); */
  backdrop-filter: blur(6px);
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlurBehind.R0 {
  backdrop-filter: blur(0px);
}
.BlurBehind.R3 {
  backdrop-filter: blur(3px);
}

.BlurBehind.R20 {
  backdrop-filter: blur(20px);
}

.BlurBehindInner {
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  box-sizing: border-box;
}

.Button {
  min-height: 54px;
  border-radius: 10px;
  border-width: 2px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: auto; */
  font-weight: bold;
  font-size: 18px;

}

.Button.BlueOutline {
  border: 2px solid #7EBBC6;
  color: #7EBBC6;

}

.Breadcrumbs {
  color: white;
  fontSize: 14px;
  font-weight: lighter;
}


.LeftPad30 {
  padding-left: 30px;
}

.Breadcrumbs.Blue {
  color: #4DA2B0
}

.CenterText {
  text-align: center;
}

.Clause {
    margin-bottom: 35px;
}
.Clause.expanded {
    /* border: 2px solid black;
    margin: -10px -10px -10px -10px;
    padding: 10px 30px 10px 10px; */
    outline: 1px solid #F2F2F2;
    outline-offset: 8px;
    /* box-shadow: 5px 5px 0 1pt grey; */
    border-radius: 5px;
}

.Clause:last-child{
    margin-bottom: 0px;
}

.ClauseExpansion {
  /* height: 100px; */
  padding-top: 12px;
  padding-bottom: 15px;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #F8F8F8;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  /* outline: 4px solid grey; */
}

.ClauseOption {
  /* margin-bottom: 10px; */
  cursor: pointer;
}


.ClauseOptionDivider {
  height: 1px;
  /* width: 100%; */
  margin-left: 15px;
  margin-right: 15px;
  background-color: #F2F2F2;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ClauseTextContainer {
  /* padding-top: 10px; */
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  overflow: visible;
}

.ClauseInner {
  /* border: 5px solid red; */
  /* vertical-align: middle; */
  display: flex;
  /* align-items: center; */

}

.ClauseIcon {
  height: 69px;
  width: 69px;
  /* float: left; */
  vertical-align: middle;
  margin-right: 13px;
  background-color: #D8D8D8;
  border-radius: 7px;
  cursor: pointer;
}

.ClauseOptionLine1 {
  display: block;
  /* vertical-align: middle; */
  /* border: 1px solid red; */
}

.ClauseOptionLine1 p {
  margin-bottom: 7px;
  margin-top: 0px;
  font-size: 15px;
}
.ClauseOptionLine2 p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.ClauseOptionLine2 {
  display: block;
  /* vertical-align: middle; */
  /* border: 1px solid blue; */
}

.ClauseOptionTitle {
  /* float:left; */
  display: inline-block;
  /* vertical-align: bottom; */
  /* font-size: 15px; */
  margin-right: 10px;
  font-weight: bolder;
  color: #48525D
}

.ClauseOptionDefault {
  /* float:left; */
  display: inline-block;
  /* vertical-align: bottom; */
  color: #CC9947;
  margin-right: 15px;
  /* font-size: 14px; */
}

.ClauseOptionContextData {
  /* float:left; */
  display: inline-block;
  /* vertical-align: bottom; */
  color: #CC9947;
  font-size: 15px;
}

.ClauseOptionInfoText {
  /* float:left; */
  /* vertical-align: bottom; */
  color: #92989E;
  font-size: 15px;
  margin-left: 10px;
}

.ClauseDescription {
  color: #46525E;
  font-size: 15px;
  opacity: 0.7;
  font-style: italic;
}

.ClauseListNumber {
  font-size: 18px;
  color: #D8D8D8;
  font-weight: 600;
  margin: 0 0 0 0;
}

.ClauseText {
  font-size: 16px;
  color: #48525D;
  /* float: right; */
  vertical-align: middle;
  line-height: 1.5;
  margin-top: 0px;
  text-align: left;
  margin-bottom: 0px;
  overflow: visible;
}

.ClauseText.center {
  text-align: center;
}

.ClauseListItem {
  font-size: 16px;
  color: #48525D;
  margin-bottom: 0px;
  margin-top: 0px;
}


.ClauseTextInput {
  /* font-family: Lato; */
  font-size: 16px;
  color: #48525D;
  min-width: 50px;
  border-radius: 0;
  max-width: 100vw;
  /* float: right; */
  /* width: 100%; */
  vertical-align: middle;
  line-height: 1.5;
  border: 0px;
  border-bottom: 2px solid #85BBDC;
  padding-bottom: 2px;
  /* margin-left: 5px; */
  /* margin-right: 2px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* padding-left: 0px;
  padding-right: 0px; */
  text-align: left;
  box-sizing: border-box;
  /* overflow: visible; */
  -moz-appearance: none;/* older firefox */
   -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
   appearance: none; /* rest */
   resize: none;
}

.ClauseTextInput.Lato {
 font-family: Lato;
}


.ClauseTextInput.placeholder {
  color: #A6ACB2;
}

.ClauseTextInput.Error {
  border-bottom-color: #D66E6E;
}

.ClauseTextInput.center {
  text-align: center;
}
.ClauseTextInput.right {
  text-align: right;
}

.ClauseTextInput.currency {
  min-width: 25px;
}
.ClauseTextInput.number {
  min-width: 25px;
}

.ClauseTextInput:focus {
  outline: none;
  box-shadow: none;
}
.ClauseTextInput:focus-visible {
  /* border: none; */
  box-shadow: none;
}

.ClauseTextInput.not-editable {
  border-color: gainsboro;
  background-color: transparent;
}
.ClauseTextInput:disabled {
  border-color: gainsboro;
  background-color: transparent;
}

.ClauseTextInput.W100 {
  width: 100%;
}

#ClauseTextInputHiddenHack, .ClauseTextInputHiddenHack {
  position: absolute;
  opacity: 0;
  white-space: pre-wrap;
}


.ClauseText.placeholder {
  color: #A6ACB2;
}


.ClauseTextDropdown {
  font-size: 16px;
  /* font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;*/
  color: #48525D;
  background-color: transparent;
  min-width: 50px;
  /* float: right; */
  /* width: 100%; */
  vertical-align: middle;
  line-height: 1.5;
  border: 0px;
  border-bottom: 2px solid #85BBDC;
  padding-bottom: 0px;
  /* margin-left: 2px;
  margin-right: 2px; */
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  box-sizing: border-box;
  -moz-appearance: none;/* older firefox */
   -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
   appearance: none; /* rest */
   resize: none;
}


.ClauseTextDropdown.placeholder {
  color: #A6ACB2;
}

.ClauseTextDropdown:focus {
  background-color: transparent;
  border: 0px;
  border-bottom: 2px solid #85BBDC;
  outline: 0;
}
.ClauseTextDropdown:disabled {
  border-color: Gainsboro;
  background-color: transparent;
}

.ClauseTextDropdown.Error {
  border-bottom-color: #D66E6E;
}

.ClauseTextUnderlined {
  /* text-decoration: underline;
  text-decoration-color: #85BBDC;
  text-decoration-style: solid;
  text-decoration-thickness: 3px;
  text-underline-offset: 1px; */
  border-bottom: 2px solid #85BBDC;
  padding-bottom: 2px;
  /* margin-left: 2px;
  margin-right: 2px; */
  padding-left: 2px;
  padding-right: 2px;
  white-space: pre-wrap;
}

.ClauseTextUnderlined.placeholder {
  color: #A6ACB2;
  border-bottom: 2px solid #85BBDC;
  text-decoration: none;
  min-width: 100px;
  text-align: center;
  display: inline-block;
  white-space: pre-wrap;
}
.ClauseTextUnderlined.disabled {
  border-color: Gainsboro;
  background-color: transparent;
}
.ClauseTextUnderlined:disabled {
  border-color: Gainsboro;
  background-color: transparent;
}

.ClauseTextUnderlined.Error {
  border-bottom-color: #D66E6E;
}

.ClauseTextInputArea {
  font-size: 16px;
  color: #48525D;
  width: 100%;
  min-width: 50px;
  min-height: 30px;
  vertical-align: middle;
  line-height: 1.6;
  border: 0px;
  padding: 0;
  margin-left: 0px;
  /* padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px; */
  text-align: left;
  -moz-appearance: none;/* older firefox */
   -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
   appearance: none; /* rest */
   /* resize: none; */
  resize: vertical;
  overflow: hidden;
	box-sizing: border-box;
  border: 0;
  border-bottom: 2px solid #85BBDC;
}


.ClauseTextInputArea.indent {
  margin-left: 15px;
  margin-top: 5px;
}

.ClauseTextInputArea.underline {
  text-decoration: underline;
  text-decoration-color: #85BBDC;
  text-decoration-thickness: 2px;
  text-underline-offset: 4px;
}
.ClauseTextInputArea.bottomLine:focus {
  outline: 0;
  border: 0;
  border-bottom: 2px solid #85BBDC;
}

.ClauseTextInputArea:focus {
  outline: 0;
  border: 0;
  border-bottom: 2px solid #85BBDC;
}

.ClauseTextInputArea.bottomLine {
  text-decoration: none;
  border: 0;
  border-bottom: 2px solid #85BBDC;
}

.ClauseTextInputArea.noBottomLine {
  border: 0;
}

.ClauseTextInputArea:disabled {
  border-color: Gainsboro;
  background-color: transparent;
}

.ClauseTextInputArea.noResize {
  resize: none;
}
/* .ClauseTextInputArea.noResize::-webkit-resizer {
  display: none;
} */

.ClauseTextInputArea::placeholder {
  color: #A6ACB2;

}

.ClauseTextInputArea.Error {
  border-bottom-color: #D66E6E;
}


.ClauseErrorBox {
  background-color: #D66E6E;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ClauseErrorBox p {
  margin: 0;
}

.ClauseErrorBox.warn {
  background-color: #DBA955;
}

.ClauseErrorText {
  color: white;
  font-size: 14px;
  margin: 0;
  margin-block-end: 0;
}
.ClauseErrorIcon {
  /* background-color: #D66E6E; */
  margin: 0px;
  margin-right: 10px;
  height: 20px;
}





/* .ContinueButtonTitle {
  text-transform: capitalize;
  font-size: 18px;
  color: #7EC696;
} */

.DashedList {
  list-style-type: none;
  text-align: left;
}

.DashedList li {
  margin-bottom: 10px;
}

.DashedList li:before {
  content: "-";
  position: absolute;
  margin-left: -20px;
}

.DeleteIcon {
  width: 18px;
  height: 18px;
}

.Footer {
  /* min-height: 150px; */
  padding-top: 30px;
  margin-top: 80px;
  padding-bottom: 30px;
  background-color: #7ebbc6;
  /* position: absolute; */
  /* position: static; */
  /* align-self: flex-end; */
  /* justify-self: flex-end; */
  bottom: 0;
  width: 100%;
}

.Footer.White{
  background-color: transparent;
}

.FormLabelUpper {
  color: #A6ACB2;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}

.FloatingAgreementNav {
  background-color: lightSteelBlue;
  border-radius: 8px;
  /* border: 2px solid blue; */
  padding: 10px;
  position: fixed;
  left: 20px;
  top: 150px;
  max-width: 150px;

}

.AgreementMakerH1 {
  font-family: 'Bebas', 'Roboto', sans-serif;
  font-size: 42px;
  color: #D66E6E;
}

.AgreementMakerH2 {
  /* font-family: 'Bebas', 'Roboto', sans-serif; */
  font-family: 'Arvo', sans-serif;
  font-weight: normal;
  font-size: 24px;
  color: #7EBBC6;
}


.formProgressBar {
  display: flex;
  flex-direction: row;
  /* margin: auto; */
  justify-content: center;
  margin-bottom: 40px;
  /* align-items: center; */
  /* width: 150px; */
  /* background-color: pink; */
}


.formProgressItem {
  height: 7px;
  /* width: 30; */
  flex: 1;
  max-width: 50px;
  background-color: #4DA2B0;
  margin-left: 2px;
  margin-right:  2px;
  border-radius: 5px;
  opacity: 0.5;
}

.formProgressBar2 {
  display: flex;
  flex-direction: row;
  height: 7px;
  /* margin: auto; */
  justify-content: left;
  margin-bottom: 40px;
  /* align-items: center; */
  /* width: 150px; */
  background-color: #BEDDE2;
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 20px;
  /* opacity: 0.5; */
}

.formProgressBar2Complete {
  height: 7px;
  background-color: #4DA2B0;
  border-radius: 5px;
}

.formProgressItem.complete {
  opacity: 1;
}
.formProgressItem2.complete {
  opacity: 1;
}

.FullSizeScreen {
  min-width: 100vw;
  min-height: 100vh;
  /* max-width: 100vw; */
  /* max-height: 100vh; */
  /* width: 100%;
  height: 100%; */
  /* background-color: #4DA2B0; */
  background-color: #7ebbc6;
  overflow: hidden;
  flex: 1,
}

.Flex {
  display: flex;
}

.Flex.W100 {
  width: 100%;
}

.W100vw {
  width: 100vw;
}

.H100vh {
  height: 100vh;
}

.H100pc {
  height: 100%;
}

.Flex.Column {
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}

.AlignCenter {
  align-items: center;
}

.JustifyCenter {
  justify-content: center;
}

.JustifySpaceBetween {
  justify-content: space-between;
}
.JustifySpaceAround {
  justify-content: space-around;
}

.AlignStart {
  align-items: flex-start;
}
.JustifyStart {
  justify-content: flex-start;
}

.AlignEnd {
  align-items: flex-end;
}

.JustifyEnd {
  justify-content: flex-end;
}

.FullOverlay{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right:0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(150, 150, 150, 0.3);
  /* opacity: 0.5; */
  backdrop-filter: blur(6px);
}


.FlexGrid {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  counter-reset: flex-grid-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
}

.FlexGrid.AutoNumber > div{
  counter-increment: flex-grid-counter 1; /* Increase the counter by 1. */
}


.FlexGrid.AutoNumber * div.NumberCircle p:after{
  content: counter(flex-grid-counter); /* Apply counter before children's content. */
}



.FlexGrid.Gap30 {
  gap: 30px;
}

.FlexGrid.Gap20 {
  gap: 20px;
}

.InlineBlock {
  display: inline-block;
}
.InlineFlex {
  display: inline-flex;
}


.LoginLogo {
  /* display: none; */

}

.LoginTitleContainer {

}

.LoginTitle {
  /* display: none; */
  font-family: 'Bebas', 'Roboto', sans-serif;
  font-size: 42px;
  color: #56A3B1;
}

.LoginInput {
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  border: 1px solid #E1E1E1;
  min-width: 330px;
  font-size: 18px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
}

.LoginEmailInput {
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  border: 1px solid #E1E1E1;
  min-width: 330px;
  font-size: 18px;
}



.LoginEmailInput:focus {
  outline: none;
  box-shadow: none;
  /* border: none; */
}

.LoginForm {
  display: flex;
  flex-direction: column;
}

.LoginForm > * {
    margin-bottom:  15px;
}

.LoginPage {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  margin:auto;
  /* width: 100vw; */
  /* max-width: 400px; */
  height: 100vh;
  margin-top: 20vh;
  /* padding: auto; */
}

.NavBar {
  width: 100%;
  height: 100px;
  background-color: #4DA2B0;
  background-color: #7ebbc6;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);
  /* display: flex; */
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  /* justify-content: space-between; */
}

.NavBar2O {
  width: 100%;
  height: 100px;
  display: flex;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  /* justify-content: space-between; */
}

.NavBar2 {
  width: 100%;
  /* height: 100px; */
  display: flex;
  /* padding: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px; */
  box-sizing: border-box;
  text-align: center;
  /* justify-content: space-between; */
}

.NavBar2.Blue {
  background-color: #7ebbc6;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);
}

.NavBar2Inner {
  width: 100%;
  /* height: 100px; */
  display: flex;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  /* justify-content: space-between; */
}

/* .NavBarMobile {
  display: none;
  width: 100%;
  height: 70px;
  background-color: #4DA2B0;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);
  padding: 10px;
  padding-top: 12px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: -30px;
} */

.NavBarMobile {
  display: none;
  width: 100%;
  height: 60px;
  padding-top: 15px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: -15px;
  /* justify-content: space-between; */
}

.NavBarMobile2 {
  display: none;
  width: 100%;
  height: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: -15px;
  /* justify-content: space-between; */
}

.NavBarMobile2.Blue {
  background-color: #7ebbc6;
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
}


.NavBarLogo {
  align-self: center;
  /* margin-right: 20px;
  margin-left: 20px; */
  padding-right: 20px;
  padding-left: 20px;
  /* float: "left" */
  margin: auto;
  cursor: pointer;
}

.NavBarLeft {
  flex:1
}

.NavBarRight {
  flex:1
}

.NewTenancyMidSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: column;
  width: 100%;
  flex: 1;
  animation: slide-in-from-right 500ms;
  animation-iteration-count: 1;
  /* animation-direction: reverse; */


}

.P10 {
  padding: 10px
}
.P20 {
  padding: 20px
}
.P30 {
  padding: 30px
}
.P40 {
  padding: 40px
}
.P50 {
  padding: 50px
}

.PT10 {
  padding-top: 10px
}
.PT20 {
  padding-top: 20px
}
.PT30 {
  padding-top: 30px
}
.PT40 {
  padding-top: 40px
}
.PT50 {
  padding-top: 50px
}

.PB10 {
  padding-bottom: 10px
}
.PB20 {
  padding-bottom: 20px
}
.PB30 {
  padding-bottom: 30px
}
.PB40 {
  padding-bottom: 40px
}
.PB50 {
  padding-bottom: 50px
}

.Page {
  width: 100%;
  /* display: flex; */
  min-height: 100vh;
  height: 100%;
  /* text-align: center; */

}

.PageMain {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.Page2 {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background-color: #7ebbc6;
  /* text-align: center; */
}
.Page3 {
  min-height: 100vh;
  background-color: #7ebbc6;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* align-items: flex-flex-start; */
  /* justify-content: center; */
}

.Page3.White {
  background-color: white;
}

.Page3.LeftAlign {
  align-items: flex-start;
}

.Page2.Padding {
  padding-left: 20px;
  padding-right: 20px;
}

.PageMain2 {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  border-radius: 15px;
  max-width: 800px;
  min-width: 300px;
  width: 100%;
}

.PageMain2.Blue {
  background-color: transparent;
  margin-top: 0px;
}

.PageTitle {
  /* font-family: 'Bebas', 'Roboto', sans-serif; */
  font-family: 'Bebas', 'Roboto', sans-serif;
  font-weight: lighter;
  font-size: 42px;
  /* color: #56A3B1; */
  color: #4DA2B0;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0px;
}

.PageTitle.white {
  color: white;
}

.PageTop {
  width: 100%;
  box-sizing: border-box;
}

.PadH30 {
  padding-left: 30px;
  padding-right: 30px;
}

.PlanBox {
  background-color: #48525D;
  background-color: white;
  border-radius: 8px;
  padding: 22px;
  margin: 5px;
  min-width: 280px;
  max-width: 450px;
  min-height: 150px;
  /* color: white; */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 30px lightGrey;

}

.PlanBox h1 {
  font-size: 45px;
}

.Section {
   max-width: 800px;
   /* border: 1px solid red; */
}

.SectionClauses {

}

.SectionStarterText {
  font-size: 18px;
  color: #48525D;
  /* float: right; */
  vertical-align: middle;
  line-height: 1.5;
  margin-top: 0px;
  text-align: left;
  margin-bottom: 20px;
}

.SelectedAreas {
  padding-left: 55px;
  margin-top: 12px;
}

.SelectedAreasAddButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ListAddItemButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;
}

.ModalCard {
  background-color: white;
  border-radius: 20px;
  border: 5px solid #4DA2B0;
  box-sizing: border-box;
  max-width: 500px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  font-family: Lato, HelveticaNeue;
  /* padding: 30px; */
}

.ModalCard h1{
  font-size: 50px;
  font-family: Bebas;
  color: #4DA2B0;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 0px;

}

.ModalCard h2{
  font-size: 24px;
  font-family: HelveticaNeue;
  font-weight: normal;
  color: #4DA2B0;
  text-align: center;
  margin-top: 0px;
  max-width: 300px;
}

.ModalCard h3{
  font-size: 18px;
  font-weight: bold;
  color: #4DA2B0;
  /* text-align: center; */
  margin-top: 0px;
  margin-bottom: 0px;
  max-width: 300px;
}

.ModalCardBottom {
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
  background-color: #7EBBC6;
  padding: 30px;
  box-sizing: border-box;
  color: white;
  font-size: 20px;
}

.ModalCardBottom ul > li {
  margin-bottom: 12px;
  /* font-size: 21px; */
}

.ComplianceModalMarkdown {
  color: #48525D;
}

.ComplianceModalMarkdown h1, h2, h3{
  color: #4DA2B0;
  max-width: none;
}

.ComplianceModalMarkdown h2, h3{
  color: #4DA2B0;
  text-align: left;
}

.ComplianceModalMarkdown H2 {
  font-weight: 600;
  font-size: 16px;
  font-family: HelveticaNeue;
  margin: 0 !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

.ComplianceModalMarkdown a{
  color: #4DA2B0;
}

.ComplianceModalMarkdown li:not(:last-child){
  margin-bottom: 10px;
}

.TenancyOverviewSectionTitle {
  /* font-family: 'Bebas', 'Roboto', sans-serif; */
  font-family: 'Bebas', 'Roboto', sans-serif;
  font-weight: lighter;
  font-size: 28px;
  color: #4DA2B0;
  color: #767C82;
  text-align: left;
  margin-bottom: 25px;
  margin-top: 0px;
}

.MarginAuto {
  margin: auto;
}

.M10 {
  margin: 10px
}

.M15 {
  margin: 15px
}

.M20 {
  margin: 20px
}
.M30 {
  margin: 30px
}
.M40 {
  margin: 40px
}
.M50 {
  margin: 50px
}

.MT10 {
  margin-top: 10px
}
.MT20 {
  margin-top: 20px
}
.MT30 {
  margin-top: 30px
}
.MT40 {
  margin-top: 40px
}
.MT50 {
  margin-top: 50px
}

.ML10 {
  margin-left: 10px
}

.ML15 {
  margin-left: 15px
}

.ML20 {
  margin-left: 20px
}
.ML30 {
  margin-left: 30px
}
.ML40 {
  margin-left: 40px
}
.ML50 {
  margin-left: 50px
}

.MultiUserFormRow {
  /* display: table-row; */
  width: auto;
  vertical-align: top;
  margin-bottom: 20px;
}

.No-icon {
  list-style-type: none;
}

.SelectedAreaRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
}

.SelectedAreaRowLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PeopleRowLeft {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.SelectedAreaRowRight {
  align-items: center;
  margin-left: 18px;
}

.SelectedAreaIcon {
  width: 30px;
  height: 30px;
  margin-right: 14px;
}

.PeopleListItemIcon {
  width: 30px;
  height: 30px;
  margin-right: 14px;
}

.ListItemIcon {
  width: 30px;
  height: 30px;
  margin-right: 14px;
}

.SelectNotEditableWarning {
  font-size: 14px;
  color: Grey;
  font-style: italic;
  margin-bottom: 20px;
}

.SectionContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* margin:auto; */
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  padding-top: 30px;
  /* justify-content: space-between; */
  /* -webkit-justify-content: space-between; */
  /* margin-left: 30px;
  margin-right: 30px; */
  /* padding-left: 30px;
  padding-right: 30px; */
  /* max-width: 482px; */
  min-height: 100vh;
  /* border: 1px solid blue; */
  width: 100%;
}

.SectionTitle {
  /* font-family: 'Bebas', 'Roboto', sans-serif; */
  font-family: 'Arvo', sans-serif;
  font-size: 42px;
  font-size: 36px;
  color: #56A3B1;
  text-align: center;
  vertical-align: middle;
  margin-left: 40px;
  margin-right: 40px;
}

.SectionHeaderMain {
  vertical-align: middle;
  text-align: center;
  width: 100%;
}

.SectionHeaderAll {
  vertical-align: middle;
  text-align: center;
  width: 100%;
}

.SigningTsCs {
  font-size: 14px;
  text-align: left;
}

.AbsoluteBottom {
  position: absolute;
  bottom: 0;
}

.TASignaturesStatusList {
  list-style-type: none;
  /* display: flex; */
}

.TASignaturesStatusListItem{
  /* list-style-type: none; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  border: 2px solid lightSteelBlue;
  padding: 8px;
  border-radius: 8px;
  color: #48525D;
}

.TASignaturesStatusListItem.signed {
  border: 2px solid YellowGreen;
}

.TASigsListStatusBadge {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background-color: lightSteelBlue;
  color: white;
  margin-left: 20px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20px;
  min-width: 60px;
}

.TASigsListStatusBadge.signed {
  background-color: YellowGreen;
}

.TASigsListStatusBadge.unSigned {
  background-color: #D66E6E;
}

.TASigsListName {
  font-weight: bold;
}

.TASigsListDate {
  color: #96A3B1;
}

.TASigsListRole {
  color: #96A3B1;
  text-align: left;
  margin-left: 10px;
}


.TAShareBox {
  background-color: #EAEAEA;
  padding: 30px;
  margin-top: 50px;
  border-radius: 8px;
}

.TextOverlay {
  position: absolute;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.TextOverlay.CenterText {
  text-align: center;
}

.TextOverlay.Center {
  text-align: center;
}

.TextOverlay.Bottom {
  /* top: 0; */
  bottom: 0px;
}

.ShareLinkInputBox {
  padding: 10px 10px 10px 10px;
  border-radius: 8px;
  border: 0px;
  background-color: white;
  font-size: 14px;
  min-width: 200px;
  /* width: 100%; */
  /* height: 30px; */
}

.ScrollV {
  overflow-y: scroll;
}

.ScrollH {
  overflow-x: scroll;
}

.SigPad {
  /* margin-top: 40px;
  margin-bottom: 40px; */
  flex-direction: column;
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 400px;
  min-width: 280px;
  border: 1px solid silver;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  /* margin: auto; */
}

.sigCanvas {
  /* border: 1px solid silver; */
  border-radius: 5px 5px 0 0;
  background-color: #FBF9F3;
  width: 100%;
  /* min-width: 280px;
  max-width: 400px; */
  height: 200px;
  margin: auto;
}

.SigningArea {
  margin-top: 20px;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

input:disabled {
  /* background-color: red; */
  border-color: Gainsboro;
}
textarea:disabled {
  /* background-color: red; */
  border-color: Gainsboro;
}

@media (max-width: 500px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .NavBar {
    display: none;
  }
  .NavBar2 {
    display: none;
  }

  .NavBarMobile {
    /* display: flex; */
  }
  .NavBarMobile2 {
    display: flex;
  }

  .Breadcrumbs {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .PageMain2 {
    min-width: 600px;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
