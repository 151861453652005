.Addressdropdown {
  background-color: white;
  min-height: 65px;
  border-color: #DDD9D9;
  border-width: 0.5;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  justify-content: center;
  font-family: HelveticaNeue;
  font-size: 18px;
  color: #48525D;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  overflow-y: scroll;
  max-height: 200px;
}

.Addresssuggestionrow {
  border-bottom: 0.5px solid #7ebbc6;
  font-size: 14px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.Addresssuggestiontext {
  margin: 0px;
}

.Continuebutton {
  margin: 0px;
  margin-top: 25px;
  width: 100%;
  box-sizing: border-box;
}

.Formlabel {
  font-family: lato;
  font-size: 24px;
  color: white;
  font-weight: 200px;
}

.Formlabel2 {
  font-family: arvo;
  font-size: 18px;
  color: white;
  font-weight: 200px;
}

.Formlabelmini {
  font-family: lato;
  font-size: 18px;
  color: white;
  font-weight: 500px;
  text-align: left;
  align-self: flex-start;
}

.Topsection {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.Midsection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1px;
}

.Midsectionfrombottom {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex: 1px;
  margin-bottom: 40px;
}

.Main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Bottomsection {
  display: flex;
  justify-content: flexStart;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  box-sizing: border-box;
  max-width: 620px;
}

.Textbox {
  background-color: white;
  min-height: 65px;
  border: 0px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  justify-content: center;
  font-family: HelveticaNeue;
  font-size: 18px;
  color: #48525D;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.Maincontent {
  flex: 1px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.Toptext {
  justify-content: center;
  text-align: center;
}

.Headerp {
  font-family: Arvo;
  font-weight: bold;
  color: white;
  font-size: 36px;
  margin: 0px;
  margin-bottom: 11px;
  p-align: center;
}

.Subheaderp {
  font-family: Arvo;
  color: white;
  font-size: 26px;
  p-align: center;
  padding-horizontal: 20px;
  margin-bottom: 15px;
  margin-top: 0px;
}

 /* end of the imports */

.OutlineGridBox {
  border: 2px solid #4DA2B0;
  flex: 1;
  border-radius: 10px;
  padding: 20px;
  color: #4DA2B0;
  background-color: white;
  font-family: Lato;
}

.OutlineGridBox H2 {
  font-weight: 600;
  font-size: 16px;
  font-family: HelveticaNeue;
  margin: 0;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}


.OutlineGridBox p {
  margin-block-end: 10px;
  font-family: Lato;
}

.OutlineGridBox li {
  font-family: Lato;
}

.OverviewBox {
  min-width: 300px;
  max-width: 300px;
  position: relative;
  align-self: flex-start;
  /* align-self:auto; */
  align-self:stretch;
}

.OverviewBox.Complete {
  border-color: #B6D488;
}

.OverviewBox.MH260 {
  max-height: 260px;
}

.OverviewBox.Complete h2{
  color: #B6D488;
}

.OverviewBox.Complete .NumberCircle{
  color: #B6D488;
  border-color: #B6D488;
}


.OverviewGrid {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 30px;
  /* width: 100%; */
  /* max-width: 700px; */
  counter-reset: flex-grid-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
  /* justify-content: flex-start; */
  justify-content: center;
}


@media (max-width: 700px) {
  .OverviewGrid {
    justify-content: center;
  }
}

.OverviewGrid > div{
  counter-increment: flex-grid-counter 1; /* Increase the counter by 1. */
}


.OverviewGrid * div.NumberCircle p:after{
  content: counter(flex-grid-counter); /* Apply counter before children's content. */
}

.OverviewBoxNote {
  font-size: 14px;
  font-style: italic;
  color: #A6ACB2;
  text-align: center;
}

.NumberCircle {
  border-radius: 500px;
  border: 2px solid #4DA2B0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
}

.NumberCircle p {
  /* visibility: hidden */
}

.NumberCircle.AutoNumber p:after {
  /* content: '1'; */
  content: counter(flex-grid-counter) ". "; /* Apply counter before children's content. */

}


.NumberCircle.TopLeft {
  position: absolute;
  top: -15px;
  left: -15px;
  background-color: white;
}
