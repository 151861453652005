@keyframes slide-in-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in-from-right {
  0% {
    transform: translateX(+100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out-to-left {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    display: none;
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.ErrorAlertShake {
  animation: pulse 500ms;
  animation-iteration-count: 2;
}

.SlideOutLeft {
  animation: slide-out-to-left 500ms;
}

.SlideInFromRight {
  animation: slide-in-from-right 500ms;
}
