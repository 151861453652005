.AddPropertyTitle {
  font-size: 18px;
  color: #56A3B1;
}

.NewPropertyDiv {
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 20px;
  /* border: 1px solid #F0F0F0; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  position: relative;
  min-width: 250px;
}

.PropertiesPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 30px;
  margin-right: 30px; */
  /* padding-left: 30px;
  padding-right: 30px; */

  padding-top: 30px;
}

.PropertyBox {
  flex: 1;
  background-color: #E4C28C;
  /* background-color: #46525E; */
  min-width: 250px;
  min-height: 100px;
  border-radius: 10px;
  /* padding: auto; */
  padding: 20px 20px 20px 20px;
  /* justify-content: center;
  align-items: center; */
  font-family: 'Bebas Neue', sans-serif;
  flex-wrap: wrap;
  /* border: 3px solid transparent; */
  color: white;
  box-sizing: border-box;
}

.PropertyBox.MW300 {
  max-width: 300px
}

.PropertyBox.White {
  background-color: white;
  color: #4DA2B0;
  /* border: 0; */
  border: 3px solid transparent;
  /* max-width: 350px; */
}

.PropertyBox:hover {
  /* background-color: #46525E; */
  cursor: pointer;
  /* border: 3px solid orange; */
  outline: 3px solid orange;
  box-sizing: border-box;
  /* background-color: #E4C28C; */

}

.PropertyBox:first-child {
  /* background-color: red; */
  flex: auto;
  width: 100%;
  cursor: auto;
  /* outline: 3px solid orange; */
}

.PropertyBox.NoStretch:first-child {
  /* background-color: red; */
  flex: 1;
  /* width: 100%; */
  cursor: pointer;
  /* border: 0; */
}

.PropertyBox:not(:first-child) {
  /* max-width: 350px; */
}

.PropertyBox:first-child:hover {
  /* border: 3px solid transparent; */
  outline: 3px solid orange;
}

.PropertyBoxDates {
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}

.PropertiesGrid {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  margin-top: 40px;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.PropertiesGrid > div {
  /* margin: 10px; */
}

.PropertyBoxInfo {
  margin-top: 15px;
  /* margin-left: 15px; */
  /* padding-top: 15px; */
}

.PropertyBoxL1{
  margin: 0;
  font-size: 48px;
  font-size: 32px;
  /* font-size: 25.5vw; */
  font-family: 'Bebas Neue', sans-serif;
  font-family: 'Arvo', sans-serif;
  /* font-family: 'HelveticaNeue-light', sans-serif; */
  /* color: white; */
}

.PropertyBoxL2{
  margin: 0;
  font-size: 20px;
  font-family: 'Bebas Neue', sans-serif;
  font-family: 'Arvo', sans-serif;
  font-family: 'HelveticaNeue-light', sans-serif;
  /* color: white; */
}

.PropertyBoxLink{
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  /* font-family: 'Bebas Neue', sans-serif; */
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
  color: white;
  margin-bottom: 5px;
}

.PropertyBoxSectionHeader{
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  /* font-family: 'Bebas Neue', sans-serif; */
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
  color: white;
  margin-bottom: 5px;
  text-align: left;
}

.PropertyBoxTenancy {
  min-height: 40px;
  min-width: 100px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.PropertyBoxTenancy:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.PropertyBoxTenancyNew {
  height: 50px;
  width: 50px;
  border: 2px solid transparent;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PropertyBoxTenancyNew:hover {
  /* border: 2px solid #E4C28C; */
  background-color: white;

}

@media (max-width: 420px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .PropertyBoxTenancy {
    width: 100%;
  }
}

.PropertyBoxTenanciesContainer {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
  margin-top: 10px;
}

.PropertyBoxTenancyDate {
  font-size: 16px;
  /* color: #56A3B1; */
  color: white;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
}

.PropertyBoxTenancyInfo {
  font-size: 16px;
  /* color: #56A3B1; */
  color: white;
  font-weight: normal;
  margin: 0px;
}
